import axios from "axios";

const getBaseURL = async () => {
  // if (localStorage.getItem("global") === "true") {
  //   if (!localStorage.getItem("currency")) {
  //     const response = await axios.get(
  //       "https://app.kyveli.me/api/footer-details"
  //     );
  //     localStorage.setItem("currency", response?.data?.data?.default_currency);
  //   }
  //   return "https://app.kyveli.me/api/";
  // } else {
  //   try {
  //     const res = await axios.get("https://app.kyveli.me/api/check-ip");
  //     // const previews = localStorage.getItem("country");
  //     // localStorage.setItem("country", res.data.countryCode);
  //     if (!localStorage.getItem("currency")) {
  //       const response = await axios.get(res.data.url + "/footer-details");
  //       localStorage.setItem(
  //         "currency",
  //         response?.data?.data?.default_currency
  //       );
  //     }
  //     return res.data.url;
  //   } catch (e) {
  //     if (!localStorage.getItem("currency")) {
  //       const response = await axios.get(
  //         "https://app.kyveli.me/api/footer-details"
  //       );
  //       localStorage.setItem(
  //         "currency",
  //         response?.data?.data?.default_currency
  //       );
  //     }
  //     return "https://app.kyveli.me/api/";
  //   }
  // }

  if (!localStorage.getItem("currency")) {
    const response = await axios.get(
      "https://app.kyveli.me/api/footer-details"
    );
    localStorage.setItem("currency", response?.data?.data?.default_currency);
  }

  return "https://uae-arabi-app.kyveli.me/api/";
};

export const service = axios.create({
  baseURL: await getBaseURL(),
});

service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    config.headers["Currency"] = localStorage.getItem("currency");
    config.headers["Accept-Language"] = localStorage.getItem("i18nextLng");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
